<template>
  <div>
    <v-container style="position: relative;">

    <div class="d-flex justify-space-between align-center">
      <p class="music__subjecttxt">عناوین برتر {{cat.title}}</p>
      <router-link :to="{name:'posts',params:{id:cat.id,title:cat.title}}" custom v-slot="{ navigate , href }">
      <a @click="navigate" :href="href" role="link" class="music__showalltxt">نمایش همه</a>
      </router-link>
    </div>

    <swiper class="itemswiper px-4" :options="swiperOption">

      <swiper-slide v-for="item in items" :key="item.id" style="position: relative;" class="overflow-hidden">

        <router-link :to="{name:'single',params:{id:item.id,title:item.title}}" custom v-slot="{ navigate , href }">
        <div @click="navigate" role="link" :style="{background : 'url('+$store.state.thumbnail + item.image +')'}" class="image-card" style="z-index: 0;">
          <a :href="href" role="link"><img class="image-card images ma-0" :src="$store.state.thumbnail + item.image" ></a>
          <a :href="href" role="link"><p class="ma-0 mt-2 text-right" style="color: #afafb4;font-size: 12px;text-justify: inter-word;text-align: justify;" >{{ item.title }}</p></a>
        </div>
        </router-link>

      </swiper-slide>

    </swiper>

    <div :id="'prev'+index" class="button-prev swiper-button-prev swiper-button-white arrowsize-r" slot="button-prev"></div>
    <div :id="'next'+index" class="button-next swiper-button-next swiper-button-white arrowsize-l" slot="button-next"></div>

    </v-container>
  </div>
</template>

<script>

import {Swiper, SwiperSlide} from 'vue-awesome-swiper';
import SwiperCore, {EffectFade} from 'swiper';
import {Navigation} from 'swiper';
SwiperCore.use([Navigation]);
SwiperCore.use([EffectFade]);

export default {
  name: 'App',

  props : {
    items : null,
    cat : null,
    index : null,
  },
  components :{
    Swiper,
    SwiperSlide
  },

  data() {
    return {
      swiperOption: {
        slidesPerView: 5,
        spaceBetween: 0,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        breakpoints: {
          1280: {
            slidesPerView: 5,
            spaceBetween: 0,
          },
          1125: {
            slidesPerView: 5,
            spaceBetween: 0,
          },
          960: {
            slidesPerView: 4,
            spaceBetween: 0,
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 0,
          },
          450: {
            slidesPerView: 3,
            spaceBetween: 0,
          },
          320: {
            slidesPerView: 2,
            spaceBetween: 10,
          },

          300: {
            slidesPerView: 2,
            spaceBetween: 10,
          }
        },
        navigation: {
          nextEl: '#'+'prev'+this.index,
          prevEl: '#'+'next'+this.index,
        }
      },
    }
  },
};
</script>

<style>
.music__subjecttxt{
  font-size: 18px;
  user-select: none;
  color: #b6b6b7;
}

.music__showalltxt{
  font-size: 18px;
  color: #3c858c !important;
  user-select: none;
  cursor: pointer;
  margin-left: 48px !important;
}



</style>




<style lang="scss">

@import "../../assets/css/itemswiper";

.swiper-wrapper{
  width: 150px !important;
}

.arrowsize-r {

  left : -5px !important;
  color: #570654 !important;
  --swiper-navigation-size: 25px !important;
}

.arrowsize-l {

  right : -5px !important;
  color: #570654 !important;
  --swiper-navigation-size: 25px !important;
}

.swiper-button-prev{
  background-image: url(../../assets/images/home/arrow-left.png);
  background-repeat: no-repeat;
  background-size: 80% auto;
  background-position: center;
}

.swiper-button-next{
  background-image: url(../../assets/images/home/arrow-right.png);
  background-repeat: no-repeat;
  background-size: 80% auto;
  background-position: center;
}

.swiper-button-next::after {
  display: none;
  font-family: 'swiper-icons' !important;
  font-weight: 1000;
  content: "next";
}

.swiper-button-prev::after {
  display: none;
  font-family: 'swiper-icons' !important;
  font-weight: 1000;
  content: "prev";
}

.image-card {
  width: 120px;
  height: 180px;
  border-radius: 5px;
  position: relative;
  background-size: cover;
  margin: 0px 6px 97.7px 6px;
  cursor: pointer;
  transition: 0.2s all;
}

.skeleton-image-card {
  width: 120px;
  height: 180px;
  border-radius: 5px;
  margin: 11.88px 6px 97.7px 6px;
}

@media only screen and (min-width: 1264px) {
  .image-card {
    margin: 0px 11.88px 97.7px 11.88px;
    height: 192px;
    width: 132px;
  }

  .skeleton-image-card {
    width: 132px;
    height: 192px;
    border-radius: 5px;
    margin: 11.88px 11.88px 97.7px 11.88px;
  }

}

.image-card:before {
  content: "";
  position: absolute;
  width: 90%;
  height: 103%;
  z-index: -1 !important;
  background-image: inherit;
  background-size: cover;
  border-radius: 5px;
  filter: blur(12px);
  opacity: 0.6;
  left: 50%;
  bottom : 0%;
  transform: translate(-50%, 5%);
}

.image-card:hover:before{
  opacity: 0.8;
}



.images:hover {
  width: 124px !important;
  height: 184px !important;
}

@media only screen and (min-width: 1264px) {
  .images:hover {
    width: 135px !important;
    height: 195px !important;
  }
}

/*.justmusicresponsive{
  justify-content: center;
}

@media only screen and (min-width: 768px){
  .justmusicresponsive{
    justify-content: space-between;
  }
}

@media only screen and (min-width: 1264px){
  .justmusicresponsive{
    justify-content: start;
  }
}*/

</style>