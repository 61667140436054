<template>
  <div>
    <v-container class="main-container d-flex flex-wrap">
      <div class="col-12 col-mm-8 pa-1 order-mm-0">
        <TopContent></TopContent>
      </div>
      <div class="col-12 col-mm-4 py-6 px-lg-6 px-2 order-mm-1 order-2" style="position: relative">
        <LiveSearch></LiveSearch>
        <LastPosts></LastPosts>
      </div>

      <div class="col-12 py-6 order-mm-2" >

        <div v-if="loading">
        <div class="d-flex justify-space-between align-center mt-8">
          <p class="music__subjecttxt">عناوین برتر ...</p>
          <p class="music__showalltxt">نمایش همه</p>
        </div>

        <div class="d-flex flex-wrap justmusicresponsive" >
          <v-skeleton-loader
              v-for="n in 6"
              :key="n"
              dark
              class="skeleton-image-card"
              type="image"
          ></v-skeleton-loader>
        </div>

        </div>
        <div v-else>
        <HomeContent v-for="(d,i) in data" :key="i" :items="d.items" :cat="d.cat" :index="i" ></HomeContent>
        </div>

      </div>

    </v-container>

  </div>
</template>

<script>
import TopContent from "@/components/TopContent";
import HomeContent from "@/components/Main/HomeContent";
import LastPosts from "../components/LastPosts";
import LiveSearch from "../components/LiveSearch";
import axios from "axios";


  export default {
    name: 'Home',
    data(){
      return {
        data:[],
        loading : true,
      }
    },
    components: {
      TopContent,
      HomeContent,
      LastPosts,
      LiveSearch
    },
    metaInfo() {
      return {
        title:'صفحه اصلی',
        link: [{rel: 'canonical', href: this.$store.state.url}]
      }
    },
    created() {

      axios.get(this.$store.state.api + 'home').then(response => {
        this.data = response.data.data;
        this.loading = false;
      })
    }
  }
</script>


<style lang="scss">
.justmusicresponsive{
  justify-content: center;
}

@media only screen and (min-width: 768px){
  .justmusicresponsive{
    justify-content: space-between;
  }
}

@media only screen and (min-width: 1264px){
  .justmusicresponsive{
    justify-content: start;
  }
}

.music__subjecttxt{
  font-size: 18px;
  user-select: none;
  color: #b6b6b7;
  margin-bottom: 16px !important;
}

.music__showalltxt{
  font-size: 18px;
  color: #3c858c;
  user-select: none;
  cursor: pointer;
  margin-bottom: 16px !important;
  margin-left: 48px !important;
}


</style>


